import { useAccount } from "@hooks/auth";
import * as React from "react";
import { withProviders } from "@providers/hoc";
import { Address, formatEther } from "viem";
import { useJutsuBalance } from "@hooks/jutsu";

const ConnectLabelComponent: React.FC<{}> = ({}) => {
  const { address, connector } = useAccount();
  const prevAddress = React.useRef<Address | null>(null);
  const prevConnector = React.useRef<string | null>(null);

  const { balance, isError } = useJutsuBalance();

  React.useEffect(() => {
    if (address && address !== prevAddress.current) {
      prevAddress.current = address;
    }

    if (address && connector?.id !== prevConnector.current) {
      // @ts-expect-error
      H.identify(address, {
        wallet: address,
        connectorName: connector?.name,
        connectorId: connector?.id,
      });
    }

    if (
      address &&
      (address !== prevAddress.current ||
        connector?.id !== prevConnector.current)
    ) {
      // @ts-expect-error
      H.identify(address, {
        wallet: address,
        connectorName: connector?.name,
        connectorId: connector?.id,
      });
    }
  }, [address, prevAddress.current, connector, prevConnector.current]);

  if (!address) return null;

  const address_truncated = address.slice(0, 6) + "..." + address.slice(-4);
  null;

  return (
    <>
      <span
        className="truncate label connected"
        data-connect={!!address_truncated ? "true" : "false"}
      >
        {isError ? "-" : balance ? formatEther(balance) : "0"} $JUTSU
      </span>
    </>
  );
};

export const Label = withProviders(ConnectLabelComponent);
